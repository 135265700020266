import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { IoIosArrowForward } from "react-icons/io";

const ArticleListing = ({ heading, tagline, content, allPosts }) => {
	const [numOfItemsToShow, setNumberOfItems] = useState(4);
	// const catName = (catList) => {
	// 	let outStr = "";
	// 	if (catList.length > 0) {
	// 		let allCat = catList.filter((item) => item.parentId);
	// 		if (allCat.length === 0) {
	// 			allCat = catList;
	// 		}
	// 		allCat.forEach(
	// 			(ele, idx) =>
	// 				(outStr += `${ele.name} ${idx < allCat.length ? "" : ", "}`)
	// 		);
	// 	}

	// 	return outStr;
	// };
	const handleLoadMore = () => {
		setNumberOfItems((prev) => prev + 4);
	};

	return (
		<section className="d-flex align-items-center py-5 py-md-8">
			<Container>
				<Row className={`justify-content-start`}>
					<Col lg={8} className={`mb-4 mb-lg-0 text-start`}>
						<span className="text-black">{tagline}</span>
						<h1 className="my-auto text-black pb-2">{heading}</h1>
						<div
							className="text-black pt-2"
							dangerouslySetInnerHTML={{ __html: content }}
						></div>
					</Col>
				</Row>
				{allPosts.nodes.length > 0 && (
					<Row className="g-5 mt-4">
						{allPosts.nodes.slice(0, numOfItemsToShow).map((item, i) => (
							<Col xl={6} key={i} className=" ">
								<Row className="g-4 align-items-center ">
									<Col md={6}>
										<div style={{ overflow: "hidden" }} className="rounded-5 ">
											<GatsbyImage
												loading="lazy"
												image={
													item.featureImage?.featureImage.node?.localFile
														.childImageSharp.gatsbyImageData
												}
												alt={item.featureImage?.featureImage.node?.altText}
												className="w-100 article-img-height"
											/>
										</div>
									</Col>
									<Col md={6}>
										<div className=" ps-3 ps-md-0">
											<span className="text-light-black bg-lighter-grey py-1 px-2">
												{item.blogPostFields.blogPostBanner.category}
											</span>
											<h5 className="text-light-black fs-5 montserrat-black my-2">
												{item.title}
											</h5>
											<p className="text-light-black fs-6 blog-content fw-light">
												{item.blogPostFields.blogPostBanner?.excerpt}
											</p>
											<Button
												as={Link}
												to={`/blog/${item.slug}`}
												size="lg"
												className="py-2 px-0 montserrat-semibold fs-6 bg-transparent border-0"
											>
												Read More
												<IoIosArrowForward className="fs-6 ms-2" />
											</Button>
										</div>
									</Col>
								</Row>
							</Col>
						))}
					</Row>
				)}
				{allPosts.nodes && numOfItemsToShow < allPosts.nodes.length && (
					<Button
						size="lg"
						className="px-4 py-3 montserrat-bold fs-6 bg-transparent border-light-black mt-6 d-flex mx-auto"
						onClick={handleLoadMore}
						variant="secondary"
					>
						Load more
					</Button>
				)}
			</Container>
		</section>
	);
};

export default ArticleListing;
