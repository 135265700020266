import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const NczAnnouncements = ({
	heading,
	tagline,
	content,
	allCategory,
	allPosts,
	backgroundColor,
}) => {
	const [visiblePosts, setVisiblePosts] = useState([]);
	const [selectedFilter, setSelectedFilter] = useState("viewAll");
	const [postsToShow, setPostsToShow] = useState(6);

	const handleCatClick = (name) => {
		setSelectedFilter(name);

		setPostsToShow(6); // Reset posts to show when filter changes
	};

	useEffect(() => {
		console.log(allPosts.nodes[0].blogPostFields.blogPostBanner.category);
		const filteredPosts =
			selectedFilter === "viewAll"
				? allPosts.nodes
				: allPosts.nodes.filter(
						(post) =>
							post.blogPostFields.blogPostBanner.category[0] === selectedFilter
				  );

		setVisiblePosts(filteredPosts.slice(0, postsToShow));
	}, [allPosts, selectedFilter, postsToShow]);

	const handleLoadMore = () => {
		setPostsToShow((prev) => prev + 6);
	};

	// const catName = (catList) => {
	// 	let outStr = "";
	// 	if (catList.length > 0) {
	// 		const allCat = catList.filter((item) => item.parentId);

	// 		allCat.forEach(
	// 			(ele, idx) =>
	// 				(outStr += `${ele.name}${idx < allCat.length - 1 ? ", " : ""}`)
	// 		);
	// 	}

	// 	return outStr;
	// };
	const totalPostCount =
		selectedFilter === "viewAll"
			? allPosts.nodes.length // If 'View All' is selected, consider all posts
			: allPosts.nodes.filter(
					(post) =>
						post.blogPostFields.blogPostBanner.category[0] === selectedFilter
			  ).length; // Else, filter posts by the selected category
	return (
		<section
			className="d-flex align-items-center py-5 py-md-8"
			style={{ backgroundColor: backgroundColor }}
		>
			<Container>
				<Row className={`justify-content-center`}>
					<Col lg={7} className={`mb-4 mb-lg-0 text-center`}>
						<span className="text-white">{tagline}</span>
						<h1 className="my-auto text-white pb-2">{heading}</h1>
						<div
							className="text-white pt-2"
							dangerouslySetInnerHTML={{ __html: content }}
						></div>
					</Col>
				</Row>
				<div className="d-flex justify-content-center mt-5 mt-md-7">
					<div className="bg-white d-flex align-items-center rounded-5 flex-wrap gap-4 gap-md-5 py-3 px-5">
						<div
							className={`${
								selectedFilter === "viewAll"
									? "border-bottom border-2 border-black montserrat-bold"
									: ""
							} text-black`}
							style={{ cursor: "pointer" }}
							onClick={() => handleCatClick("viewAll")}
						>
							View all
						</div>
						{allCategory.map((item, i) => (
							<div
								key={i}
								onClick={() => handleCatClick(item.slug)}
								style={{ cursor: "pointer" }}
								className={`${
									selectedFilter === item.slug
										? "border-bottom border-2 border-black montserrat-bold"
										: ""
								}`}
							>
								{item.name}
							</div>
						))}
					</div>
				</div>
				{visiblePosts.length > 0 ? (
					<Row>
						{visiblePosts.map((item, i) => (
							<Col md={6} lg={4} key={i} className="mt-6">
								<Link to={`/blog/${item.slug}`}>
									<GatsbyImage
										loading="lazy"
										image={
											item.featureImage.featureImage.node?.localFile
												.childImageSharp.gatsbyImageData
										}
										alt={item.featureImage.featureImage.node?.altText}
										className="border border-white border-2 rounded-5"
										style={{ width: "100%", height: "300px" }}
									/>
								</Link>
								<span
									className="d-block montserrat-light mt-3 text-white"
									style={{ fontSize: "14px" }}
								>
									{item.date}
								</span>
								<span className="text-white">
									{item.blogPostFields.blogPostBanner.category}
								</span>
								<h5 className="text-white fs-5 montserrat-black ">
									{item.title}
								</h5>
								<div className="">
									<p className="text-white fs-6 blog-content">
										{item.blogPostFields.blogPostBanner?.excerpt}
									</p>
									{/* <div className="d-flex mt-4">
										{item.blogPostFields.blogPostBanner
											.blogPostBannerAuthorProfileImage?.node.sourceUrl ? (
											<GatsbyImage
												image={
													item.blogPostFields.blogPostBanner
														.blogPostBannerAuthorProfileImage?.node?.localFile
														.childImageSharp.gatsbyImageData
												}
												alt={
													item.blogPostFields.blogPostBanner
														.blogPostBannerAuthorProfileImage?.node?.altText
												}
												className="rounded-5"
											/>
										) : (
											<AvatarIcon />
										)}

										<div className="px-4">
											<span className="fs-6 text-white">
												{
													item.blogPostFields.blogPostBanner
														.blogPostBannerAuthorName
												}
											</span>
										</div>
									</div> */}
								</div>
							</Col>
						))}
					</Row>
				) : (
					<div className="mt-6 text-white d-flex justify-content-center">
						No Posts Found
					</div>
				)}
				{visiblePosts.length > 0 && visiblePosts.length < totalPostCount && (
					<div className="d-flex justify-content-center mt-4">
						<Button
							variant="secondary"
							className="px-4 py-3 w-100 w-md-auto text-light-black montserrat-bold  "
							onClick={handleLoadMore}
						>
							Load more
						</Button>
					</div>
				)}
			</Container>
		</section>
	);
};

export default NczAnnouncements;
