import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

const StayUpToDate = ({
	heading,
	content,
	notice,
	image,
	imageBgColor = "#E8BC38",
}) => {
	const [email, setEmail] = useState("");
	const apiKey = process.env.GATSBY_MY_SECRET_API_KEY;
	const [toast, setToast] = useState({ show: false, message: "", bg: "" });

	const handleSubmit = (event) => {
		event.preventDefault();
		fetch("/.netlify/functions/subscribe", {
			method: "POST",
			body: JSON.stringify({ email: email }),
			headers: {
				"Content-Type": "application/json",
				"x-api-key": apiKey,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.message !== "Error subscribing to list.") {
					setToast({ show: true, message: data.message, bg: "primary" });
				} else
					setToast({
						show: true,
						message: "Error subscribing to list.",
						bg: "danger",
					});
			})
			.catch((error) => {
				setToast({
					show: true,
					message: "Error subscribing to list.",
					bg: "danger",
				});
			});
	};
	return (
		<section className="py-3 py-lg-8">
			<Container>
				<Row className={`align-items-lg-center`}>
					<Col lg={6} className="mb-4 mb-lg-0 text-start pe-lg-6">
						<h2 className="my-0 pt-2">{heading}</h2>
						<div
							className="py-4"
							dangerouslySetInnerHTML={{ __html: content }}
						></div>
						<div className="d-flex align-items-center">
							<Form className="w-100" onSubmit={handleSubmit}>
								<Row className="mb-4">
									<Col md="7">
										<Form.Control
											type="email"
											placeholder="Enter your email"
											className="mr-sm-1 mb-4 mb-md-0 border-light-black py-2 rounded-0"
											required
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											style={{ height: "52px" }}
										/>
									</Col>
									<Col md="3" className="px-lg-0">
										<Button
											size="lg"
											type="submit"
											className="w-100 text-black h-100 montserrat-bold px-2"
											variant="secondary"
										>
											Sign up
										</Button>
									</Col>
								</Row>
							</Form>
						</div>
						<div>
							<span className=" small-text-new montserrat-light">
								{parse(notice)}
							</span>
						</div>
					</Col>
					<Col
						lg={6}
						className="mb-4 mb-lg-0 text-start d-none d-lg-block pt-4 ps-4 position-relative"
					>
						<div className="position-relative">
							<GatsbyImage
								loading="lazy"
								image={image?.localFile.childImageSharp.gatsbyImageData}
								alt={image?.altText}
								className="rounded-5"
								style={{
									height: "380px",
								}}
							/>
						</div>

						<div
							className="bg-image position-absolute w-50 h-50 top-0 left-0"
							style={overlayImageStyle()}
						>
							<div
								className="d-none d-md-block"
								style={{
									background: imageBgColor,
									width: "100%",
									height: "100%",
									borderTopRightRadius: "30px",
									borderBottomLeftRadius: "30px",
								}}
							></div>
						</div>
					</Col>
				</Row>
			</Container>
			<ToastContainer
				style={{ position: "fixed", top: 0, right: 0, zIndex: 1050 }}
				position="top-end"
				className="p-3"
			>
				<Toast
					onClose={() => setToast({ ...toast, show: false })}
					show={toast.show}
					delay={3000}
					autohide
					bg={toast.bg}
				>
					<Toast.Header>
						<strong className="me-auto">Notification</strong>
					</Toast.Header>
					<Toast.Body className="text-white">{toast.message}</Toast.Body>
				</Toast>
			</ToastContainer>
		</section>
	);
};

export const overlayImageStyle = () => ({
	zIndex: "-1",
	left: 0,
	right: "unset",
});

export default StayUpToDate;
